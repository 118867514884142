import AuthService from '@/services/auth.service';

const initialState = {
    token: null,
    anonymousUserIdentifier: null
};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, authorizationCode) {
            return AuthService.login(authorizationCode).then(
                token => {
                    commit('loginSuccess', token);
                    return Promise.resolve(token);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            )
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        }
    },
    mutations: {
        loginSuccess(state, token) {
            state.token = token;
            //@TODO
            state.token.expires_at = 'ss'
        },
        loginFailure(state) {
            state.token = null;
        },
        logout(state) {
            state.token = null;
        },
        refreshToken(state, token) {
            state.token = token;
        },
        updateAnonymousUserIdentifier(state, anonymousUserIdentifier) {
            state.anonymousUserIdentifier = anonymousUserIdentifier;
        },
        resetState(state) {
            Object.assign(state, initialState);
        }
    }
};
