import apiAxiosInstance from '@/services/axios/api.axios';
import apiHelper from '@/helpers/api';

class UserService {
    usersMe() {
        return apiAxiosInstance.get(apiHelper.usersMeEndpoint)
            .then(function (response) {
                return response.data.data.user;
            });
    }
}

export default new UserService();
