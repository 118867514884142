import apiAxiosInstance from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";
import TokenService from "@/services/token.service";
import store from "@/store";
import Toastify from "toastify-js";

class AnonymousUsersService {
    anonymousLogin(user) {
        return apiAxiosInstance.post(apiHelper.anonymousUsersEndpoint,
            {
                'login': user.username,
                'password': user.password
            }
        ).then(function (response) {
            TokenService.setAnonymousUserIdentifier(response.data.data.anonymous_user.identifier);
            return response.data.data;
        })
          .catch(function (err) {
            for (var prop in err.response.data.data) {
              Toastify({
                text: err.response.data.data[prop],
                className: "error",
              }).showToast();
            }
        });
    }

    getAnonymousUser(anonymousUserId) {
        return apiAxiosInstance.get(apiHelper.anonymousUsersGetEndpoint.replace('{identifier}', anonymousUserId));
    }

  getCriteria(isAvailableForOpenBoost, isAvailableForBoostPack, isAvailableForMeeting) {
    let params = {};
    if (isAvailableForOpenBoost) {
      params.is_available_for_open_boost = isAvailableForOpenBoost;
    } else if (isAvailableForBoostPack) {
      params.is_available_for_boost_pack = isAvailableForBoostPack;
    } else if (isAvailableForMeeting) {
        params.is_available_for_meeting = isAvailableForMeeting;
    }
    return apiAxiosInstance.get(apiHelper.getCriteria, {params});
  }

  getCriteriaOpenBoostRate(anonymousUserId) {
    if(store.state.auth.anonymousUserIdentifier) {
      return apiAxiosInstance.get(apiHelper.anonymousUsersWellBeingEndpoint);
    } else {
      return apiAxiosInstance.get(apiHelper.coachWellBeingEndpoint.replace('{identifier}', anonymousUserId));
    }
  }

    batchWellBeing(wellBeingCriterias) {
        return apiAxiosInstance.post(apiHelper.anonymousUsersWellBeingBatchEndpoint, wellBeingCriterias);
    }

    getMeetingsByIdentifier(anonymousUserIdentifier, limit = false) {
        let params = {};
        if (limit) {
            params.limit = limit;
        }
        return apiAxiosInstance.get(
            apiHelper.anonymousUsersMeetingsEndpoint.replace('{identifier}', anonymousUserIdentifier),
            {
                params
            });
    }
}

export default new AnonymousUsersService();
