<template>
  <div class="container notfound">
    <h1>404</h1>
    <h2>Oops! Rien n'a été trouvé</h2>
    <p>La page que vous recherchez n'existe pas ou n'existe plus. <a href="/">Retour à la page d'accueil</a></p>
  </div>
</template>

<script>
export default {
  name: "NotFoundView"
}
</script>

<style scoped>

</style>