<template>
  <div class="container notfound">
    <h1>...</h1>
    <h2>Redirection en cours</h2>
  </div>
</template>

<script>
export default {
  name: "HomeRedirection.vue"
}
</script>

<style scoped>

</style>