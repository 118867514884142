import {
  createApp
} from 'vue'
import App from './App.vue'
import store from "./store";
import router from './router'
import './assets/scss/global.scss'
import './helpers/vaadin-style-sheets'
import 'moment/locale/fr'

import setupInterceptors from '@/services/interceptors/setupInterceptors';

setupInterceptors(store);

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
