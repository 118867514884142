import apiAxiosInstance from "@/services/axios/api.axios";
import portailAxiosInstance from "@/services/axios/portail.axios";
import TokenService from "@/services/token.service";
import apiHelper from "@/helpers/api";
import store from '../../store';

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

const setup = () => {
    apiAxiosInstance.interceptors.request.use(
        config => {
            const token = TokenService.getLocalAccessToken();
            const anonymousUserIdentifier = TokenService.getAnonymousUserIdentifier();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            if (anonymousUserIdentifier) {
                config.headers["X-Anonymous-User-Identifier"] = anonymousUserIdentifier;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    apiAxiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const originalRequest = error.config;

            if (error.config && originalRequest.url !== "/auth/token" && error.response && error.response.data.error === 'access_denied') {
                // Access Token was expired
                if (error.response.status === 401 && !originalRequest._retry) {

                    if (isRefreshing) {
                        return new Promise(function(resolve, reject) {
                            failedQueue.push({resolve, reject})
                        }).then(token => {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            return portailAxiosInstance(originalRequest);
                        }).catch(err => {
                            return Promise.reject(err);
                        })
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise(function (resolve, reject) {
                        portailAxiosInstance.post("/oauth/token", {
                            'grant_type': 'refresh_token',
                            'client_id': apiHelper.clientId,
                            'refresh_token': TokenService.getLocalRefreshToken(),
                        })
                        .then(function (response) {
                            TokenService.setToken(response.data);
                            originalRequest.headers['Authorization'] = 'Bearer ' + TokenService.getLocalAccessToken();

                            store.dispatch("user/usersMe").then(() => {
                                resolve(portailAxiosInstance(originalRequest));
                            });
                        })
                        .catch((err) => {
                            processQueue(err, null);
                            store.dispatch('auth/logout');
                            reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false
                        })
                    })
                }
            }

            return Promise.reject(error);
        }
    );
};

export default setup;
