<template>
  <div class="main-container two-columns-left">
    <div class="left">
      <Menu />
    </div>
    <div class="right">
      <MyMeetings />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Coaching/Dashboard/Menu";
import MyMeetings from "@/components/Coaching/Dashboard/MyMeetings";

export default {
  name: "DashboardView",
  components: {
    Menu,
    MyMeetings
  }
}
</script>