<template>
  <div class="my-meetings-container">
    <h2>Mes rendez-vous</h2>
    <div class="tabs-button-container">
      <div class="tabs-button"
           :class="{ active: currentIndex === 0 }"
           @click="changeTab(0)">
        à venir
      </div>
      <div class="tabs-button"
           :class="{ active: currentIndex === 1 }"
           @click="changeTab(1)">
        historique
      </div>
    </div>
    <div class="tabs-container">
      <div class="tabs-wrapper" ref="wrapper">
        <pending-meetings-component :meetings="pendingMeetings" @cancelMeeting="cancelMeeting" />
        <closed-meetings-component :meetings="closedMeetings" />
      </div>
    </div>
    <router-link
        :to="{ name: 'CoachingCalendar'}"
        class="grey-link">
      Voir le calendrier
    </router-link>
  </div>
</template>

<script>
import MeetingCardListVue from "@/components/Meeting/Dashboard/MeetingCardList.vue";
import meetingsService from '@/services/meetings.service';
import Toastify from "toastify-js";

export default {
  name: "MyMeetings",
  created() {
    meetingsService.getMeetings('pending', null, 2, '+starts_at')
        .then((response) => {
          if (response.data.data.meetings.length) {
            this.pendingMeetings = response.data.data.meetings;
          }
        });
    meetingsService.getMeetings('closed', null, 2, '+starts_at')
        .then((response) => {
          if (response.data.data.meetings.length) {
            this.closedMeetings = response.data.data.meetings;
          }
        });
  },
  data() {
    return {
      currentIndex: 0,
      pendingMeetings: [],
      closedMeetings: []
    };
  },
  components: {
    pendingMeetingsComponent: MeetingCardListVue,
    closedMeetingsComponent: MeetingCardListVue,
  },
  methods: {
    changeTab(i) {
      this.currentIndex = i;
      const wrapper = this.$refs.wrapper;
      const offset = 100 * this.currentIndex;
      wrapper.style.transform = `translate3d(-${offset}%, 0, 0)`;
    },
    cancelMeeting(id) {
      meetingsService.cancelMeeting(id)
          .then(() => {
            let index = this.pendingMeetings.findIndex(x => x.id === id)
            this.pendingMeetings.splice(index, 1);
            Toastify({
              text: "Rendez-vous annulé",
              className: "success",
            }).showToast();
          });
    }
  }
};
</script>
