<template>
  <div class="meeting-card" :id="'meeting-' + meeting.id">
    <div class="meeting-infos">
      <span class="date">
        {{ startsAtDayFormatedDate }}<span class="time">{{ startsAtTimeFormatedDate }}</span></span>
      <span v-if="meeting.anonymous_user" class="name">Anonyme : {{ nickname }}</span>
      <span v-else>Utilisateur : {{ nickname }}</span>
    </div>
    <div class="meeting-actions">
      <router-link
          :to="{ name: 'MeetingDetail', params: { id: meeting.id }}"
          class="button button-pink">
        Voir le détail
      </router-link>
      <a href="#" v-if="isCancelable" @click.prevent="$emit('cancelMeeting', meeting.id)">Annuler le RDV</a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "MeetingCard",
  props: {
    meeting: Object
  },
  computed: {
    startsAtDayFormatedDate() {
      let dateInString =moment(this.meeting.starts_at).format('dddd Do MMMM');
      return dateInString.charAt(0).toUpperCase() + dateInString.slice(1)
    },
    startsAtTimeFormatedDate() {
      return moment(this.meeting.starts_at).format('HH:mm')
    },
    nickname() {
      if (this.meeting.boost_pack) {
        return this.meeting.boost_pack.user.first_name + ' ' + this.meeting.boost_pack.user.last_name;
      } else {
        return this.meeting.anonymous_user.first_name;
      }
    },
    isCancelable() {
      return this.meeting.status === 'pending';
    }
  }
}
</script>
