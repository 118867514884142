<template>
  <div class="meeting-card-list-container">
    <div v-for="(meeting, index) in meetings" :key="index">
      <meeting-card-vue :meeting="meeting" @cancelMeeting="cancelMeeting" />
    </div>
    <div v-if="!meetings.length">
      Vous n'avez pas de rendez-vous ici.
    </div>
  </div>
</template>

<script>
import MeetingCardVue from "@/components/Meeting/Dashboard/MeetingCard.vue";

export default {
  name: "MeetingCardList",
  props: {
    meetings: Array,
  },
  methods: {
    cancelMeeting(id) {
      this.$emit('cancelMeeting', id)
    }
  },
  components: {MeetingCardVue},
};
</script>
