import {default as apiAxiosInstance} from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";

class MeetingsService {
    getMeetings(status, fromDate = null, limit = false, sort = null) {
        let params = {
            status: status,
        };
        if (limit) {
            params.limit = limit;
        }

        if (fromDate) {
            params.starts_at = fromDate;
        }

        if (sort) {
          params.sort = sort;
        }

        return apiAxiosInstance.get(apiHelper.meetingsEndpoint, {
            params
        });
    }

    getMeeting(meetingId) {
        return apiAxiosInstance.get(apiHelper.meetingsByIdEndpoint.replace('{id}', meetingId));
    }

    getSubjects() {
        return apiAxiosInstance.get(apiHelper.meetingSubjectsEndpoint);
    }

    updateMeeting(meetingId, subject, summary, emergencyComment, emergencyRelay, relayHarassment, RelayMentalHealth, RelayDiscrimination, RelayOther) {
        return apiAxiosInstance.put(apiHelper.meetingsByIdEndpoint.replace('{id}', meetingId),
            {
                subject,
                summary,
                emergencyComment,
                emergencyRelay,
                relayHarassment,
                RelayMentalHealth,
                RelayDiscrimination,
                RelayOther
            });
    }

    cancelMeeting(meetingId) {
        return apiAxiosInstance.put(apiHelper.meetingsCancelEndpoint.replace('{id}', meetingId));
    }

    notShowUpMeeting(meetingId) {
        return apiAxiosInstance.put(apiHelper.meetingsNotShowUpEndpoint.replace('{id}', meetingId));
    }

    createMeeting(startsAt, anonymousUserEmail = null) {
        let params = {
            'starts_at': startsAt,
            'anonymous_user_email': anonymousUserEmail
        };
        return apiAxiosInstance.post(apiHelper.meetingsEndpoint, params);
    }

    batchCriteriasMeeting(meetingId, criteriasRate) {
        return apiAxiosInstance.post(apiHelper.batchCriterionRateMeeting.replace('{id}', meetingId), criteriasRate);
    }
}

export default new MeetingsService();
