import jwtDecode from "jwt-decode";
import store from '../store/index.js'

class TokenService {

  getLocalRefreshToken() {
    const token = this.getToken();
    return token?.refresh_token;
  }

  getLocalAccessToken() {
    const token = this.getToken();
    return token?.access_token;
  }

  getToken() {
    return store.state.auth.token;
  }

  setToken(token) {
    store.commit('auth/loginSuccess', token);
  }

  removeToken() {
    store.commit('auth/logout', null);
    this.removeAnonymousUserIdentifier();
  }

  setAnonymousUserIdentifier(anonymousUserIdentifier) {
    store.commit('auth/updateAnonymousUserIdentifier', anonymousUserIdentifier);
  }

  getAnonymousUserIdentifier() {
    return store.state.auth.anonymousUserIdentifier;
  }

  removeAnonymousUserIdentifier() {
    store.commit('auth/updateAnonymousUserIdentifier', null);
  }

  getRole() {
    let tokenDecoded = jwtDecode(this.getToken().access_token);
    return tokenDecoded.roles.shift();
  }

  isRoleUser() {
    return this.getRole() === "ROLE_USER";
  }

  isRoleUserAndIsAnonymousIdentified() {
    return this.isRoleUser() && this.getAnonymousUserIdentifier() !== null;
  }

  isRoleCoach() {
    return this.getRole() === "ROLE_COACH";
  }
}

export default new TokenService();
