const initialState = {date: null}

export const meetingStore = {
    namespaced: true,
    state: initialState,
    mutations: {
        updateMeetingDate(state, date) {
            state.date = date;
        },
        resetState(state) {
            Object.assign(state, initialState);
        }
    }
};
