import axios from "axios";
import apiHelper from "@/helpers/api";
const CancelToken = axios.CancelToken;

const instance = axios.create({
    baseURL: apiHelper.apiBaseUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

export {
    instance as default,
    CancelToken
};
