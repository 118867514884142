import apiHelper from '@/helpers/api';
import pkceHelper from '@/helpers/pkce';
import portailAxiosInstance from "@/services/axios/portail.axios";
import TokenService from "@/services/token.service";
import store from '@/store';

class AuthService {
    login(authorizationCode) {
        let PKCEChallenge = JSON.parse(localStorage.getItem('PKCEChallenge'));
        if(PKCEChallenge === null) {
            return this.redirect();
        }
        return portailAxiosInstance
            .post(apiHelper.oauthTokenEndpoint,
                {
                    'grant_type': 'authorization_code',
                    'client_id': apiHelper.clientId,
                    'redirect_uri': window.location.protocol + process.env.VUE_APP_BASE_URI + '/oauth/callback',
                    'code': authorizationCode,
                    'code_verifier': PKCEChallenge.codeVerifier
                })
            .then(function (response) {
                TokenService.setToken(response.data);
                localStorage.removeItem('PKCEChallenge')
                return response.data;
            })
            //@TODO purge locale storage and redirect to error 500 page
            .catch(function (error) {
                console.log(error);
            });
    }

    redirect() {
        let pkce = pkceHelper.generate();
        localStorage.setItem(
            'PKCEChallenge',
            JSON.stringify({
                'codeVerifier': pkce.codeVerifier,
                'codeChallenge': pkce.codeChallenge
            })
        );
        window.location.href = apiHelper.portailBaseUrl + apiHelper.oauthAuthorizeEndpoint +
            '?client_id=' + apiHelper.clientId +
            '&response_type=code' +
            '&redirect_uri=' + window.location.protocol + process.env.VUE_APP_BASE_URI + '/oauth/callback' +
            '&code_challenge=' + pkce.codeChallenge +
            '&code_challenge_method=' + apiHelper.codeChallengeMethod;
    }

    logout() {
        store.dispatch('resetAll');
        window.location.href = apiHelper.portailBaseUrl + '/logout'
    }
}

export default new AuthService();