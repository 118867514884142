<template>
  <div class="dashboard-menu-container">
    <h1 class="hello">Bonjour {{ getFirstName }}
      <span v-on:click="toggleMenu">
        <svg aria-hidden="true"
             focusable="false"
             data-prefix="fas"
             data-icon="caret-down"
             class="svg-inline--fa fa-caret-down fa-w-10"
             role="img"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 320 512">
          <path fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z">
          </path>
        </svg>
      </span>
    </h1>
    <div class="dashboard-menu" v-show="showMenu">
      <ul class="menu">
        <li>
          <router-link :to="{ name: 'Coaching' }" :disabled="true">
            Modifier mon profil
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Coaching' }" :disabled="true">
            Consulter mes factures
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Availability' }">
            Gérer mes horaires de disponibilité
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'DaysOff' }">
            Gérer mes indisponibilités / vacances
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Coaching' }" :disabled="true">
            Importer mon calendrier ICS
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    }
  },
  computed: {
    ...mapGetters(
        {
          getFirstName: 'user/getFirstName'
        }
    )
  }
}
</script>