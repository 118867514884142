import { createStore } from "vuex";
import { auth } from "./auth.module";
import { user } from "./user.module";
import { meetingStore } from "./meeting.module";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    plugins: [createPersistedState({
        storage: window.localStorage,
    })],
    modules: {
        auth,
        user,
        meetingStore
    },
    actions: {
        resetAll({ commit }) {
            commit('meetingStore/resetState');
            commit('auth/resetState');
            commit('user/resetState');
        }
    }
});

export default store;
