<template>
  <div v-if="errors.length" class="errors-container">
    <ul>
        <li v-for="error in errors" :key="error.id" :class="error.id">
          {{error.message}}
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ErrorList",
  props: {
    /**
     * @example 
     * [
     *    id: 'password',
     *    message: 'Wrong password'
     * ]
     */
    errors: {
      type: Array,
    },
  }
};
</script>