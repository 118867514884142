export default {
  apiBaseUrl: window.location.protocol + process.env.VUE_APP_API_BASE_URI,
  portailBaseUrl: window.location.protocol + process.env.VUE_APP_PORTAIL_BASE_URI,
  clientId: process.env.VUE_APP_CLIENT_ID,
  codeChallengeMethod: 'S256',
  oauthAuthorizeEndpoint: '/oauth/authorize',
  oauthTokenEndpoint: '/oauth/token',
  oauthCallbackEndpoint: '/oauth/callback',
  usersMeEndpoint: '/users/me',
  calendarsEndpoint: '/calendars',
  meetingsEndpoint: '/meetings',
  meetingSubjectsEndpoint: '/meeting-subjects',
  meetingsByIdEndpoint: '/meetings/{id}',
  meetingsCancelEndpoint: '/meetings/{id}/cancel',
  meetingsNotShowUpEndpoint: '/meetings/{id}/not-show-up',
  workingTimeSlotsEndpoint: '/working-time-slots',
  workingTimeSlotsDeleteEndpoint: '/working-time-slots/{id}',
  daysOffEndpoint: '/days-off',
  daysOffDeleteEndpoint: '/days-off/{id}',
  anonymousUsersEndpoint: '/anonymous-users/create-or-get',
  anonymousUsersGetEndpoint: '/anonymous-users/{identifier}',
  anonymousUsersMeetingsEndpoint: '/anonymous-users/{identifier}/meetings',
  anonymousUsersWellBeingEndpoint: '/criteria-rate-open-boost',
  coachWellBeingEndpoint: '/anonymous-users/{identifier}/criteria-rate-open-boost',
  anonymousUsersWellBeingBatchEndpoint: '/criteria-rate-open-boost/batch',
  coachesFromCompanyEndpoint: '/coaches',
  currentBoostPackEndpoint: '/boost-packs/current',
  changeCoachEndpoint: '/boost-packs/current/assign-coach',
  createBoostPackCriterionRateEndpoint: '/boost-packs/{id}/criteria-rate-boost-pack/batch',
  getMeetingsByBoostPackEndpoint: '/boost-packs/{boostPackId}/meetings/',
  getCriteria: '/criteria',
  getOldMeetingsByBoostPack: '/boost-packs/{id}/meetings',
  batchCriterionRateMeeting: '/meetings/{id}/criteria-rate-meeting/batch'
}
