<template>
  <div class="login-container">
    <h1>Renseignez un pseudo et un mot de passe pour prendre rendez-vous</h1>
    <span class="subtitle">Réutilisez votre pseudo et mot de passe pour pouvoir retrouver le même coach</span>
    <form action="#" method="post" @submit="handleLogin">
      <div class="inputs-container">
        <input
          type="text"
          placeholder="Pseudo"
          v-model.trim="user.username"
          :class="{
            error: errors.some((error) => error.id === 'username'),
          }"
        />
        <input
          type="password"
          placeholder="Mot de passe"
          v-model.trim="user.password"
          :class="{
            error: errors.some((error) => error.id === 'password'),
          }"
        />
      </div>
      <div class="forgot-container">
        <div class="forgot-id-container checkbox-container">
          <input type="checkbox" name="forgot-id" id="forgot-id" />
          <label for="forgot-id">C'est la première fois que je prends rendez-vous</label>
        </div>
<!--        <a class="forgot-password-link" href="#">Mot de passe oublié ?</a>-->
      </div>
      <div class="form-group flex-end">
        <input class="button button-pink" type="submit" value="Connexion" />
      </div>
      <ErrorList :errors="this.errors" />
    </form>
    <Squircle />
    <Squircle :class="'blue rotate'" />
    <Squircle :class="'yellow rotate'" />
  </div>
</template>

<script>
import Squircle from '@/components/General/Squircle.vue';
import User from '@/models/user';
import ErrorList from "@/components/General/ErrorList";
import AnonymousUsersService from "@/services/anonymousUsers.service";

export default {
  name: 'AnonymousLoginView',
  data() {
    return {
      errors: [],
      user: new User('', ''),
      loading: false
    };
  },
  methods: {
    handleLogin(event) {
      this.loading = true;
      if (this.checking()) {
        AnonymousUsersService.anonymousLogin(this.user).then(
            () => {
              this.$store.dispatch("user/usersMe").then(() => {
                this.$router.push({name: 'Meeting'})
              })
            },
            (error) => {
              console.log(error);
              this.errors.push({
                id: 'api-error',
                message: 'Une erreur est survenue.',
              });
            }
        );
      }
      event.preventDefault();
      return false;
    },
    checking() {
      this.errors = [];
      if (this.user.username.length === 0) {
        this.errors.push({
          id: 'username',
          message: 'Merci de renseigner votre identifiant.',
        });
      }
      if (this.user.password.length === 0) {
        this.errors.push({
          id: 'password',
          message: 'Merci de renseigner votre mot de passe.',
        });
      }
      return this.errors.length === 0;
    },
  },
  watch: {
    "user.username"() {
      if (this.errors.length > 0) {
        this.checking();
      }
    },
    "user.password"() {
      if (this.errors.length > 0) {
        this.checking();
      }
    },
  },
  components: {
    Squircle,
    ErrorList,
  }
};
</script>
