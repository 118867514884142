<template>
  <header>
    <a href="/">
      <img src="@/assets/images/logo.svg"/>
    </a>
    <div class="account-mini-container" v-if="hasProfile">
      <a class="account-link" href="#">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.8 9.5C8.90312 9.5 8.47188 10 7 10C5.52812 10 5.1 9.5 4.2 9.5C1.88125 9.5 0 11.3813 0 13.7V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.7C14 11.3813 12.1187 9.5 9.8 9.5ZM7 9C9.48438 9 11.5 6.98438 11.5 4.5C11.5 2.01562 9.48438 0 7 0C4.51562 0 2.5 2.01562 2.5 4.5C2.5 6.98438 4.51562 9 7 9Z"
              fill="#5A72DC"/>
        </svg>
      </a>
      <div class="profile-mini">
        <div>
          <span class="fullname">
            {{ getFullname }}
          </span>
        </div>
        <div class="logout-link-container">
          <a href="#" @click.prevent="logout">
            Se déconnecter
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "HeaderItem",
  computed: {
    ...mapGetters(
        {
          getFullname: 'user/getFullname'
        }
    ),
    hasProfile() {
      return !!this.$store.state.user.profile;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    }
  }
}
</script>

<style scoped>

</style>