import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

export default {

    generate() {
        let codeVerifier = this.generateCodeVerifier();
        return {
            codeVerifier: codeVerifier,
            codeChallenge: this.generateCodeChallenge(codeVerifier)
        }
    },

    generateCodeVerifier() {
        return this.generateRandomString(128)
    },

    generateRandomString(length) {
        let text = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    },

    generateCodeChallenge(code_verifier) {
        return this.base64URL(sha256(code_verifier))
    },

    base64URL(string) {
        return string.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    }
}