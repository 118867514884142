<script>
import TokenService from "@/services/token.service"

export default {
  created() {
    if (this.$route.query.code) {
      let authorizationCode = this.$route.query.code;
      // @TODO rename login to anonymousLogin
      this.$store.dispatch("auth/login", authorizationCode).then(() => {
          if (TokenService.isRoleUser()) {
            this.$store.dispatch("user/usersMe").then(() => {
              if (this.$store.getters['user/getBoostPack']) {
                this.$router.push({name: 'BoostPackDashboard'});
              } else {
                this.$router.push({name: 'Login'});
              }
            });

          } else if (TokenService.isRoleCoach()) {
            this.$store.dispatch("user/usersMe").then(() => {
              this.$router.push({name: 'Coaching'});
            });
          }
        },
        (error) => {
          error.toString();
        }
      );
    }
  }
}
</script>
